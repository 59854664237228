import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { FC } from "react";


interface HomeSectionPartnersProps {
}
export const HomeSectionPartners: FC<HomeSectionPartnersProps> = () => {
    
    const animation = { duration: 10000, easing: (t: any) => t }
    
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
        {
            loop: true,
            renderMode: "performance",
            drag: true,
            slides: {
                perView: 'auto'
            },
            created(s) {
              s.moveToIdx(3, true, animation)
            },
            updated(s) {
              s.moveToIdx(s.track.details.abs + 3, true, animation)
            },
            animationEnded(s) {
              s.moveToIdx(s.track.details.abs + 3, true, animation)
            }
        })

    return (
        <div className="home-partners-container">
            <div className="home-partners">

                <div ref={sliderRef} className="keen-slider">
                    <div className="keen-slider__slide">
                        <img className="chanel" width={58} height={35} alt="partner_1" src={'/assets/partners_white/illustration_partner_1.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="hyatt" width={157} height={35} alt="partner_2" src={'/assets/partners_white/illustration_partner_2.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="paris-tech" width={79} height={35} alt="partner_3" src={'/assets/partners_white/illustration_partner_3.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="oracle" width={140} height={35} alt="partner_4" src={'/assets/partners_white/illustration_partner_4.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="onoff" width={128} height={35} alt="partner_5" src={'/assets/partners_white/illustration_partner_5.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="eiffage" width={171} height={35} alt="partner_6" src={'/assets/partners_white/illustration_partner_6.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="renault" width={86} height={35} alt="partner_8" src={'/assets/partners_white/illustration_partner_8.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="big-mamma" width={75} height={35} alt="partner_9" src={'/assets/partners_white/illustration_partner_9.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="prada" width={190} height={35} alt="partner_10" src={'/assets/partners_white/illustration_partner_10.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="amnesty" width={100} height={35} alt="partner_11" src={'/assets/partners_white/illustration_partner_11.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="amazon" width={100} height={35} alt="partner_11" src={'/assets/partners_white/amazon.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="france-tv" width={100} height={35} alt="partner_11" src={'/assets/partners_white/FRANCE-TV.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="gp-explorer" width={100} height={35} alt="partner_11" src={'/assets/partners_white/GP EXPLORER.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="le-monde" width={100} height={35} alt="partner_11" src={'/assets/partners_white/LE MONDE.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="panzani" width={100} height={35} alt="partner_11" src={'/assets/partners_white/Panzani.png'}/>
                    </div>
                    <div className="keen-slider__slide">
                        <img className="william-saurin" width={100} height={35} alt="partner_11" src={'/assets/partners_white/WILLIAM_SAURIN.png'}/>
                    </div>
                </div>

            </div>
        </div>
    )
}