import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from "@mui/material";
import 'i18n';
import { t } from "i18next";
import Link from "next/link";
import router from "next/router";
import { FC } from "react";
import AmplitudeHelper from "../../utils/AmplitudeHelper";
import { BlogPost } from "../../utils/api/strapi/BlogApiHelper";
import { ROUTES_BASE } from "../../utils/consts";
import BasicCarousel from "../common/BasicCarousel";
import { LazyImage } from "../common/LazyImage";

interface HomeSectionBlogProps {
    blogPosts: BlogPost[]
    title: any
    description: any
}

export const HomeSectionBlog: FC<HomeSectionBlogProps> = (props) => {
    const {blogPosts, title, description} = props;

    return (
        <>
            {blogPosts?.length > 0 &&
            <div className="home-section-blog">
                <div className="blog-section-title">
                    <h4>{title}</h4>
                    <p>{description}</p>
                </div>

                <div className="articles">
                    <BasicCarousel 
                        autoplay={false}
                        loop={false}
                        showDots={false}
                        mode="free-snap"
                        slidesPerview={"auto"}
                        >
                        <div className="keen-slider__slide margin-div"></div>
                    {
                        blogPosts.sort((bp1, bp2) => new Date(bp2.created).getTime() - new Date(bp1.created).getTime()).slice(0, 9).map((blogPost, index) => 
                            <Link href={`${ROUTES_BASE.BLOG}/${blogPost.categorySlug}/${blogPost.slug}`} className="keen-slider__slide home-blog-post" key={index}>
                                <div className="post-image">
                                    <LazyImage src={blogPost.cover.url} width={400} height={250} alt={blogPost.title} useLoader={false}/>
                                    <div className="image-overlay"></div>
                                </div>
                                <div className="blog-info">
                                    <div>
                                        <h4>{blogPost.title}</h4>
                                        <p><strong>{blogPost.categoryName}</strong> - {'posté le : ' + blogPost.created}</p>
                                    </div>
                                    <div className="next-icon">
                                        <ArrowForwardIcon/>
                                    </div>
                                </div>
                            </Link>
                        )
                    }
                    </BasicCarousel>
                </div>

                <Button variant="contained"
                    className="sedeo-button-rounded home-button"
                    onClick={() => {
                        AmplitudeHelper.trackBlogSeen()
                        router.push({pathname: ROUTES_BASE.BLOG});
                    }}>
                    <VisibilityIcon/><span>{t('BUTTONS.ViewOurBlog')}</span>
                </Button>
            </div>}
        </>
    )
}