
export default class IconHelper {

    /**
     *
     * @param slug of the marketCategory found in our CMS.
     */
    static getMarketCategoryIconPath(slug: string){

        switch(slug){
            case "assises": return '/assets/icon_market_category_assises.svg'
            case "tables": return '/assets/icon_market_category_tables.svg'
            case "art-de-la-table": return '/assets/icon_market_category_art_de_la_table.svg'
            case "mobilier-exterieur": return '/assets/icon_market_category_mobilier_exterieur.svg'
            case "tentes-chapiteaux": return '/assets/icon_market_category_tentes.svg'
            case "mobilier-lumineux": return '/assets/icon_market_category_mobilier-lumineux.svg'
            case "mobilier-rse": return '/assets/icon_market_category_rse.svg'
            case "technique": return '/assets/icon_market_category_technique.svg'
            case "mobilier-enfant": return '/assets/icon_market_category_mobilier-enfant.svg'
            case "animations-jeux": return '/assets/icon_market_category_jeux.svg'
            case "accueil-conference": return '/assets/icon_market_category_accueil_conference.svg'
            case "vitrines-presentoirs": return '/assets/icon_market_category_vitrines-presentoirs.svg'
            case "decorations": return '/assets/icon_market_category_decoration.svg'
            case "materiel-traiteur": return '/assets/icon_market_category_materiel_traiteur.svg'
            case "animation-jeux": return '/assets/icon_market_category_animation.svg'
            case "stand-salon-parc-des-expos": return '/assets/icon_market_category_stand.svg'
            case "question-sign": return '/assets/question-sign.svg'
            case "divers-consommables": return '/assets/icon_market_category_divers-consommables.svg'
            case "speciales": return '/assets/icon_market_category_rugby.svg'
            default: return '/assets/sedeo_logo_short.svg' // TODO placeholder
        }
    }

    /**
     *
     * @param slug of the marketSubCategory found in our CMS.
     */
    static getMarketSubCategoryIconPath(slug: string){
        switch(slug){
            case "chaises": return '/assets/icon_market_sub_category_chaises.svg'
            case "chaises-pliantes": return '/assets/icon_market_sub_category_chaises-pliantes.svg'//
            case "chaises-hautes-et-tabourets": return '/assets/icon_market_sub_category_chaises-hautes-et-tabourets.svg'
            case "fauteuils": return '/assets/icon_market_sub_category_fauteuils.svg'
            case "canapes": return '/assets/icon_market_sub_category_canapes.svg'
            case "bancs-et-banquettes": return '/assets/icon_market_sub_category_bancs-et-banquettes.svg'//
            case "poufs": return '/assets/icon_market_sub_category_poufs.svg'
            case "accessoires-assises": return '/assets/icon_market_sub_category_accessoires-assises.svg'
            case "tables-rectangulaires": return '/assets/icon_market_sub_category_tables-rectangulaires.svg'
            case "tables-rondes": return '/assets/icon_market_sub_category_tables-rondes.svg'
            case "tables-hautes-et-mange-debout": return '/assets/icon_market_sub_category_tables-hautes-et-mange-debout.svg'
            case "tables-basses": return '/assets/icon_market_sub_category_tables-basses.svg'
            case "tables-gueridons": return '/assets/icon_market_sub_category_tables-gueridons.svg'
            case "tables-honneurs": return '/assets/icon_market_sub_category_tables-honneurs.svg'
            case "tables-buffets": return '/assets/icon_market_sub_category_tables-buffets.svg'//
            case "chaises-enfant": return '/assets/icon_market_sub_category_chaises-enfant.svg'
            case "tables-enfant": return '/assets/icon_market_sub_category_tables-enfant.svg'
            case "jeux-gonflables": return '/assets/icon_market_sub_category_jeux-gonflables.svg'
            case "plantes": return '/assets/icon_market_sub_category_plantes.svg'
            case "luminaires": return '/assets/icon_market_sub_category_luminaires.svg'
            case "sols": return '/assets/icon_market_sub_category_sols.svg'
            case "steles": return '/assets/icon_market_sub_category_steles.svg'
            case "consoles": return '/assets/icon_market_sub_category_consoles.svg'
            case "vitrines": return '/assets/icon_market_sub_category_vitrines.svg'
            case "vitrines-refrigerees": return '/assets/icon_market_sub_category_vitrines-refrigerees.svg'
            case "decorations-saisonnieres": return '/assets/icon_market_sub_category_decorations-saisonnieres.svg'
            case "chevalets": return '/assets/icon_market_sub_category_chevalets.svg'
            case "vestiaires": return '/assets/icon_market_sub_category_vestiaires.svg'
            case "potelets-separations": return '/assets/icon_market_sub_category_accueil-et-conference.svg'
            case "banques-accueil": return '/assets/icon_market_sub_category_banques-accueil.svg'
            case "bars-comptoirs": return '/assets/icon_market_sub_category_bars-comptoirs.svg'
            case "assises-lumineuses": return '/assets/icon_market_sub_category_assises-lumineuses.svg'
            case "tables-lumineuses": return '/assets/icon_market_sub_category_tables-lumineuses.svg'
            case "jeux": return '/assets/icon_market_sub_category_jeux.svg'
            case "jeux-arcade": return '/assets/icon_market_sub_category_jeux-arcade.svg'
            case "jeux-exterieurs": return '/assets/icon_market_sub_category_jeux-exterieurs.svg'
            case "verrerie": return '/assets/icon_market_sub_category_verrerie.svg'
            case "assiettes": return '/assets/icon_market_sub_category_assiettes.svg'
            case "couverts": return '/assets/icon_market_sub_category_couverts.svg'
            case "plateaux": return '/assets/icon_market_sub_category_plateaux.svg'
            case "access-buffets-et-contenants": return '/assets/icon_market_sub_category_access-buffets-et-contenants.svg'
            case "cuisine": return '/assets/icon_market_sub_category_art_de_la_table_cuisine.svg'
            case "glaces": return '/assets/icon_market_sub_category_glaces.svg'
            case "housses": return '/assets/icon_market_sub_category_housses.svg'
            case "nappes": return '/assets/icon_market_sub_category_nappes.svg'
            case "serviettes": return '/assets/icon_market_sub_category_serviettes.svg'
            case "assises-exterieures": return '/assets/icon_market_sub_category_assises-exterieures.svg'
            case "tables-exterieurs": return '/assets/icon_market_sub_category_tables-exterieurs.svg'
            case "chauffages": return '/assets/icon_market_sub_category_chauffages.svg'
            case "parasols": return '/assets/icon_market_sub_category_parasols.svg'
            case "tentes": return '/assets/icon_market_sub_category_tentes.svg'
            case "tentes-pliantes": return '/assets/icon_market_sub_category_tentes-pliantes.svg'// needed
            case "eclairages": return '/assets/icon_market_sub_category_eclairages.svg'
            case "videos": return '/assets/icon_market_sub_category_videos.svg'
            case "sonorisation": return '/assets/icon_market_sub_category_sonorisation.svg'
            case "scenes": return '/assets/icon_market_sub_category_scenes.svg'
            case "divers-et-consommables": return '/assets/icon_market_sub_category_divers-et-consommables.svg'
            case "effets": return '/assets/icon_market_sub_category_effets.svg'
            case "accueil-et-conference": return '/assets/icon_market_sub_category_accueil-et-conference.svg'
            case "animation-traiteur": return '/assets/icon_market_sub_category_animation-traiteur.svg'
            case "promotions": return '/assets/icon_market_sub_category_rugby.svg'
            default: return '/assets/sedeo_logo_short.svg' // TODO placeholder
        }
    }
}