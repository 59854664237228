import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";

interface CarouselProps {
  autoplay: boolean;
  mode?: "snap" | "free" | "free-snap";
  loop: boolean;
  slidesPerview: number | "auto";
  showDots: boolean;
  children: any;
  spacing?: number;
}

const CustomCarousel = (props: CarouselProps) => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      loop: props.loop,
      mode: props.mode ?? 'snap',
      slides: {
        perView: props.slidesPerview ?? "auto",
        spacing: props.spacing ?? 15,
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
      created() {
        setLoaded(true)
      },
    }, [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 2000)
        }
        if(props.autoplay){
          slider.on("created", () => {
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        }
      },
    ])

  return (
    <div className="carousel-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {props.children}
        </div>

        {loaded && instanceRef.current && props.showDots && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            )
          })}
        </div>
      )}
        {/* {loaded && slider && (
          <>
            {!isAtStart &&
              <div className='shadow-mask shadow-mask-left'
                onClick={(e: any) => {
                  if(slider.track?.details)
                    return e.stopPropagation() || slider.prev()
                  }
                }>
                {
                  slider.track?.details &&
                  <Arrow
                    left
                    disabled={currentSlide === 0}
                  />
                }
              </div>
            }

            {!isAtFinish && Math.abs(slider.track?.details?.max) !== 0 &&
                <div className='shadow-mask shadow-mask-right'
                    onClick={(e: any) =>
                      {
                        if(slider.track?.details)
                          return e.stopPropagation() || slider.next()
                      }
                    }>
                    {
                      slider.track?.details &&
                      <Arrow
                        disabled={
                          currentSlide ===
                          slider.track?.details?.slides?.length - 1
                        }
                      />
                    }
                </div>
            }
          </>
        )} */}
    </div>
  )
}

export default CustomCarousel