import Link from "next/link";
import { FC } from "react";
import { ReactSVG } from "react-svg";
import { MarketCategoryType } from "../../../typing/types";
import IconHelper from "../../../utils/IconHelper";
import RoutesHelper from "../../../utils/consts";


interface CategoryItemProps {
    marketCategory?: MarketCategoryType;
    imagePath: string;
    imageType: 'svg' | 'png'
    imageAlt?: string
    title?: string;
    subtitle?: string;
    shortDescription?: string;
    description?: {pt1: string, pt2: string};
    clickable?: boolean;
    onLinkClicked?: () => void
    customClasses?: string;
}

export const CategoryItem: FC<CategoryItemProps> = (props) => {

    const {marketCategory, imagePath, imageType, imageAlt, title, subtitle, shortDescription, description, clickable, onLinkClicked, customClasses} = props;
    const link = marketCategory && RoutesHelper.getCategoryRouteByPathName(marketCategory.slug) ? RoutesHelper.getCategoryRouteByPathName(marketCategory.slug) : ""

    const renderItem = () => {
        return (
            <div className={`category-item-container clickable ${customClasses ? customClasses : ''}`}>

                <div className="category-item-image">
                    {imageType === 'svg' ?
                        <ReactSVG src={IconHelper.getMarketCategoryIconPath(imagePath)}/>
                        :
                        <img src={imagePath} alt={imageAlt}/>
                    }
                </div>

                <div className="category-item-text-container">
                    {
                        title &&
                        <h3 className="category-item-text category-item-title">
                            {title}
                            {/* {imageType === 'svg' && <div className="sedeo-rectangle"></div>} */}
                        </h3>
                    }

                    {
                        subtitle &&
                        <h4 className="category-item-text category-item-subtitle">
                            {subtitle}
                        </h4>
                    }

                    {
                        shortDescription &&
                        <p className="category-item-text category-item-description">
                            {shortDescription}
                        </p>
                    }

                    {
                        description &&
                        <div className="category-item-text category-item-description">
                            {/* {description} */}
                            {/* <div className="info-text"> */}
                                <p>{description?.pt1}
                                <a className="email-address" href={`mailto:contact@sedeo.fr`} rel="nofollow">
                                    contact@sedeo.fr
                                </a>
                                {description?.pt2}</p>
                            {/* </div> */}
                        </div>
                    }
                </div>

            </div>
        )
    }
    return (
        <>
            {clickable && link?
                <Link href={link} 
                    aria-label={`Consulter ${title}`}
                    onClick={() => {
                        if(onLinkClicked) onLinkClicked()
                    }}>
                    {renderItem()}
                </Link>
                :
                <>{renderItem()}</>
            }
        </>
    )
}