import Link from "next/link";
import { FC, useEffect } from "react";
import { MarketCategoryType } from "../../../typing/types";
import RoutesHelper from "../../../utils/consts";
import { LazyImage } from "../../common/LazyImage";


interface HomeCategoryItemProps {
    marketCategory?: MarketCategoryType;
    imageSrc: string;
    imageAlt: string
    title?: string;
    subtitle?: string;
    description?: string;
    clickable?: boolean;
    onLinkClicked?: () => void
    customClasses?: string;
}

export const HomeCategoryItem: FC<HomeCategoryItemProps> = (props) => {

    const {marketCategory, imageSrc, imageAlt, title, subtitle, description, clickable, onLinkClicked, customClasses} = props;
    const link = marketCategory && RoutesHelper.getCategoryRouteByPathName(marketCategory.slug) ? RoutesHelper.getCategoryRouteByPathName(marketCategory.slug) : ""

    const renderItem = () => {
        
        return (
            <div className={`category-item-container clickable ${customClasses ? customClasses : ''}`}>

                <div className="category-item-image">
                    <LazyImage src={imageSrc} width={250} height={250} alt={imageAlt} useLoader={false}/>
                </div>

                <div className="category-item-text-container">
                    {
                        title &&
                        <h3 className="category-item-text category-item-title">
                            {title}
                        </h3>
                    }

                    {
                        subtitle &&
                        <h4 className="category-item-text category-item-subtitle">
                            {subtitle}
                        </h4>
                    }
                </div>

            </div>
        )
    }
    return (
        <>
            {clickable && link?
                <Link href={link} 
                    aria-label={`Consulter ${title}`}
                    onClick={() => {
                        if(onLinkClicked) onLinkClicked()
                    }}>
                    {renderItem()}
                </Link>
                :
                <>{renderItem()}</>
            }
        </>
    )
}